import Vue from 'vue';
import App from './App.vue';
import VueGtag from 'vue-gtag';
import router from './router';
import sanity from './sanity';
import VueMeta from 'vue-meta';

Vue.use(VueMeta)
Vue.use(VueGtag, {
  config: {
    id: 'G-NNVZPZKX57',
    params: {
      anonymize_ip: true
    }
  },
  pageTrackerTemplate: async (to) => {
    let title = 'Re-start | Helse og Livsstil'
    if((to.name === 'category' || to.name === 'post' || to.name === 'page') && to.params && to.params.slug) {
      const element = await sanity.fetch(`
        *[_type == $type && !(_id in path('drafts.**')) && (slug.current == $slug || _id == $slug)][0]{
          title
        }
      `, {
        type: to.name,
        slug: to.params.slug
      })

      title = `${element.title} : Re-start | Helse og Livsstil`
    }

    return {
      page_title: title,
      page_path: to.path
    }
  }
}, router)

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
