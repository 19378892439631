const currentDate = new Date()

const currentUTCDate = () => {
    let month = currentDate.getUTCMonth() + 1
    if(month < 10) {
        month = '0' + month.toString()
    }

    let currDate = currentDate.getUTCDate()
    if(currDate < 10) {
        currDate = '0' + currDate.toString()
    }

    return currentDate.getUTCFullYear() + '-' + month + '-' + currDate
}

const currentFullUTCDate = () => {
    const utcDate = currentUTCDate()

    let hours = currentDate.getUTCHours()
    if(hours < 10) {
        hours = '0' + hours.toString()
    }

    let minutes = currentDate.getUTCMinutes()
    if(minutes < 10) {
        minutes = '0' + minutes.toString()
    }

    let seconds = currentDate.getUTCSeconds()
    if(seconds < 10) {
        seconds = '0' + seconds.toString()
    }

    let milliseconds = currentDate.getUTCMilliseconds()
    if(milliseconds < 10) {
        milliseconds = '00' + seconds.toString()
    } else {
        milliseconds = '0' + seconds.toString()
    }

    return utcDate + 'T' + hours + ':' + minutes + ':' + seconds + '.' + milliseconds + 'Z'
}

const formatUTCDate = (date) => {
    const dateElement = new Date(date)

    let month = dateElement.getUTCMonth() + 1
    if(month < 10) {
        month = '0' + month.toString()
    }

    let currDate = dateElement.getUTCDate()
    if(currDate < 10) {
        currDate = '0' + currDate.toString()
    }

    let hours = dateElement.getUTCHours()
    if(hours < 10) {
        hours = '0' + hours.toString()
    }

    let minutes = dateElement.getUTCMinutes()
    if(minutes < 10) {
        minutes = '0' + minutes.toString()
    }

    let seconds = dateElement.getUTCSeconds()
    if(seconds < 10) {
        seconds = '0' + seconds.toString()
    }

    return dateElement.getFullYear() + '-' + month + '-' + currDate + 'T' + hours + ':' + minutes + ':' + seconds + '+00:00'
}

module.exports = {
    currentUTCDate,
    currentFullUTCDate,
    formatUTCDate,
}