<template>
  <header>
    <nav aria-label="top" class="top">
      <ul class="nav">
        <li tabindex="0" class="logo" onclick="location.href = '/';"></li>
        <li
          class="desktop"
          :class="{ active: isActiveMenu(item) }"
          v-for="(item, key) in items"
          :key="key"
        >
          <router-link :to="{ name: item.target, params: item.params }">{{
            item.name
          }}</router-link>
        </li>
        <li class="search-icon">
          <a
            href="javascript: void(0);"
            @click="clickSearch"
            class="flex align-center"
            ><i class="magnifying-glass gn gn-icons"></i> Søk</a
          >
        </li>
        <li class="mobile">
          <a
            href="javascript: void(0);"
            @click="clickMobileMenu()"
            class="menu gn gn-icons"
            :class="{ hamburger: !showMobileMenu, cross: showMobileMenu }"
          ></a>
        </li>
      </ul>
    </nav>
    <nav
      aria-label="expandable"
      class="expandable mobile"
      :class="{ collapsed: !showMobileMenu }"
    >
      <ul class="mobile">
        <li v-for="(item, key) in items" :key="key">
          <router-link :to="{ name: item.target, params: item.params }">{{
            item.name
          }}</router-link>
        </li>
      </ul>
    </nav>
    <div
      aria-label="expandable"
      class="expandable expandable-bg"
      :class="{ collapsed: !showSearch }"
    >
      <form type="post" class="form-group search-group" @submit="handleSearch">
        <label for="search-input" class="sr-only"
          >Søk etter innhold på siden</label
        >
        <input
          type="search"
          name="search"
          v-model="search"
          id="search-input"
          ref="search"
          placeholder="Søk..."
        />
        <button type="submit">
          Søk <i class="magnifying-glass gn gn-icons small"></i>
        </button>
      </form>
    </div>
  </header>
</template>

<script>
export default {
  computed: {
    items() {
      return [
        {
          name: "Livsstil",
          target: "category",
          params: {
            slug: "livsstil",
          },
        },
        {
          name: "Vekt og helse",
          target: "category",
          params: {
            slug: "vekt-og-helse",
          },
        },
        {
          name: "Helsebibliotek",
          target: "category",
          params: {
            slug: "helsebibliotek",
          },
        },
        /*
        {
          name: "Kun for helsepersonell",
          target: "category",
          params: {
            slug: "kun-for-helsepersonell",
          },
        },
        */
        {
          name: "Om Re-Start",
          target: "page",
          params: {
            slug: "om",
          },
        },
      ];
    },
    currentPage() {
      return this.$route.name;
    },
  },

  data() {
    return {
      showMobileMenu: false,
      showSearch: false,
      search: "",
    };
  },

  watch: {
    $route: function() {
      // Reset open elements
      this.showMobileMenu = false;
      this.showSearch = false;
      this.search = "";
    },
  },

  methods: {
    handleSearch(e) {
      e.preventDefault();
      this.$router
        .push({ name: "search", query: { q: encodeURIComponent(this.search) } })
        .catch(() => null);
      this.showSearch = false;
    },
    clickMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu;
      this.showSearch = false;
    },

    clickSearch() {
      this.showSearch = !this.showSearch;
      this.showMobileMenu = false;
    },

    isActiveMenu(v) {
      const r = this.$route;
      return (
        v.target === r.name &&
        JSON.stringify(v.params) === JSON.stringify(r.params)
      );
    },
  },
};
</script>
