import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter)

// Page layout
import Layout from './components/Layout';

// Pages
const Index = () => import('./views/pages/Index');
const Page = () => import('./views/pages/Page');
const Post = () => import('./views/pages/Post');
const Category = () => import('./views/pages/Category');
const Search = () => import('./views/pages/Search');

export default new VueRouter({
    routes: [
        {
            path: '/',
            component: Layout,
            children: [
                {
                    path: '/search',
                    name: 'search',
                    component: Search
                },
                {
                    path: '/post/:slug/',
                    name: 'post',
                    component: Post
                },
                {
                    path: '/category/:slug',
                    name: 'category',
                    component: Category
                },
                {
                    path: '/:slug',
                    name: 'page',
                    component: Page
                },
                {
                    path: '/',
                    name: 'index',
                    component: Index
                }
            ],
        }
    ],
    history: true,
    hashbang: false,
    mode: 'history',
    scrollBehavior: () => {
        return {
            x: 0,
            y: 0
        }
    }
})