<template>
        <footer>
            <div class="container">
                <ul class="el contact">
                    <li class="logo"></li>
                    <li><a href="https://www.google.com/maps/place/Tordenskiolds+gate+2,+0160+Oslo/" target="_blank" rel="noopener">Tordenskiolds gate 2, 0160 Oslo</a></li>
                    <li><a href="mailto:post@re-start.no">post@re-start.no</a></li>
                </ul>
                <ul class="el terms">
                    <li class="title">Om Re-start</li>
                    <li><router-link :to="{ name: 'page', params: { slug: 'vilkar' } }">Vilkår</router-link></li>
                    <li><router-link :to="{ name: 'page', params: { slug: 'personvernerklaering' } }">Personvern</router-link></li>
                </ul>
                <ul class="el social">
                    <li class="title">Sosiale medier</li>
                    <li><a href="https://www.facebook.com/Re-start-101384952337095" target="_blank" rel="noopener">Facebook</a></li>
                    <li><a href="https://www.instagram.com/re_start.no/" target="_blank" rel="noopener">Instagram</a></li>
                </ul>
                <ul class="el back-to-top">
                    <li class="title"><button class="btn btn-round btn-white" @click="scrollToTop()" aria-label="Gå til toppen"><i class="gn gn-icons arrow-top"></i></button></li>
                </ul>
            </div>
            <div class="container text-container">
               <p>Vil du annonsere på Re-start? Ta kontakt med oss på <a href="mailto:post@re-start.no">post@re-start.no</a><br><br><small>Re-start viser både innhold som er produsert av eget team og på oppdrag fra kommersielle aktører. Alt innhold er basert på nasjonale anbefalinger, uavhengig av oppdragsgiver.
               <br><br>I webinar for helsepersonell 15.03.23 fikk Re-start varsel fra Statens legemiddelverk for ulovlig markedsføring av reklame for medikamenter. Re-start har beklaget hendelsen til samtlige deltagere.</small>
</p>
            </div>
        </footer>
</template>

<script>
    import jQuery from 'jquery'

    export default {
        methods: {
            scrollToTop() {
                jQuery('html, body').animate({
                    scrollTop: 0
                })
            }
        }
    }
</script>
