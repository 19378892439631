<template>
    <div id="layout">
        <Header/>
        <div class="content">
            <router-view/>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import Header from './Header'
    import Footer from './Footer'

    export default {
        metaInfo: {
            title: '',
            titleTemplate: '%sRe-start | Helse og Livsstil'
        },
        components: {
            Header,
            Footer
        },
        mounted(){
        }
    }
</script>