const sanityClient = require('@sanity/client')
const date = require('./helpers/date')

if(process.env.VUE_APP_ENV !== 'production') {
    require('dotenv').config()
}

module.exports = sanityClient({
    projectId: process.env.VUE_APP_SANITY_PROJECT_ID,
    dataset: process.env.VUE_APP_SANITY_DATASET,
    apiVersion: date.currentUTCDate(),
    useCdn: process.env.VUE_APP_ENV === 'production',
    ignoreBrowserTokenWarning: true
})